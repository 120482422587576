import React from 'react'
import styles from './project-list.module.css'
import { navigate } from '@reach/router'
import Lightbox from 'lightbox-react'
import ProjectDuplicate from '../../Project/ProjectDuplicate'
import ProjectRenderPDF  from '../../ProjectRenderPDF'
import DesignList from '../../Project/DesignList'
import NotificationsList from './notifications'
import WaitLoader from '../../WaitLoader'
import ReactTooltip from 'react-tooltip'

class ProjectListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeleteOpen: false,
      duplicateOpen: false,
      projectPdfOpen: false,
      notificationsOpen: false,
      imagesPopulated: false,
      processed: 0,
      notifications: {
        complete: [],
        submitted: []
      }
    }
  }

  componentDidMount() {

  }

  onClick = () => {
    const { project, onClick } = this.props
    onClick(project)
  }

  onDelete = () => {
    const { project, handleDelete } = this.props
    this.setState({
      confirmDeleteOpen: false
    }, function(){
      handleDelete(project)
    })
  }

  onConfirmDelete = (e) => {
    e.preventDefault()
    this.setState({
      confirmDeleteOpen: true
    })
  }

  onCreateDesign = (e) => {
    const { handleCreateDesign, project } = this.props
    handleCreateDesign(project)
  }

  openProjectDuplicate = () => {
    this.setState({
      duplicateOpen: true
    })
  }

  onNotificationsOpen = () => {
    this.setState({
      notificationsOpen: true
    })
  }

  closeProjectDuplicate = () => {
    this.setState({
      duplicateOpen: false
    })
  }

  onProjectPdfOpen = () => {
    this.setState({
      projectPdfOpen: true
    })
  }

  getCanvasImage = returnImage => {
    this.props.project.designs[returnImage.index].image = returnImage.image
    this.imagesPopulated()
  }

  imagesPopulated = () => {
    let allPopulated = true
    let processed = 0
    this.props.project.designs.forEach(function(design){
      if(!design.image){
        allPopulated = false
      }else{
        processed++
      }
    })
    if(!this.state.imagesPopulated){
      this.setState({
        imagesPopulated: allPopulated,
        processed: processed,
      })
    }
    return allPopulated
  }

  designsNotifications = () => {
    let notifications = {
      submitted: [],
      complete: []
    }
    this.props.project.designs.forEach((design, i) => {
      if(design.status === 'Submitted' && design.processingInfo){
        if(!design.processingInfo.acknowledged){
          notifications.submitted.push(design)
        }
      }
      if(design.status === 'Complete'){
        if(!design?.processingInfo?.acknowledged){
          notifications.complete.push(design)
        }
      }
    })
    return notifications
  }

  render(){
    let projectStatusClass = 'inProgress'
    let notifications = this.designsNotifications()
    let submitted = null
    let complete = null
    let converted = null
    if(notifications.submitted.length > 0){
      submitted = <div>
        <button
          data-tip data-for="tooltipSubmitted"
          onClick={(e) => this.onNotificationsOpen(e)}
          title="Submitted Designs Waiting for Approval"
          className={styles['notification'] + ' ' + styles['submitted']}>
          {notifications.submitted.length}
        </button>
        <ReactTooltip id="tooltipSubmitted">
          <span>Submitted Designs Waiting for Approval</span>
        </ReactTooltip>
      </div>
    }
    if(notifications.complete.length > 0){
      complete = <div>
        <button
          data-tip data-for="tooltipProcessed"
          onClick={(e) => this.onNotificationsOpen(e)}
          title="Designs Processed And Ready"
          className={styles['notification'] + ' ' + styles['complete']}>
          {notifications.complete.length}
        </button>
        <ReactTooltip id="tooltipProcessed">
          <span>Designs Processed And Ready</span>
        </ReactTooltip>
      </div>
    }

    if(this.props.project.convertedFromNid){
      converted = <div>
      <i data-tip data-for="tooltipConverted"></i>
      <ReactTooltip id="tooltipConverted">
        <span>Data converted from an earlier version of EDS</span>
      </ReactTooltip>
      </div>
    }
    const {
      confirmDeleteOpen,
      duplicateOpen,
      projectPdfOpen,
      notificationsOpen } = this.state
    return(
      <div className={styles['projectListItem'] + ' ' + styles[projectStatusClass]}>
      <button onClick={this.onClick} className={styles['status']}>{this.props.project.name}</button>
      <button onClick={this.onClick} className={styles['statusImg'] + ' ' + styles[projectStatusClass]}>
        <div className={styles['designCount']}>{this.props.project.designs.length}</div>
      </button>
      <div className={styles['notifications']}>
        {submitted}
        {complete}
        {converted}
      </div>
      <div className={styles['projectActions']}>
        <button title="Edit" onClick={this.onClick}>
          <i className="las la-pen"></i>
        </button>
        <button title="Add" onClick={(e) => this.onCreateDesign(e)}>
          <i className="las la-plus-circle"></i>
        </button>
        <button title="Copy" onClick={this.openProjectDuplicate}>
          <i className="las la-copy"></i>
        </button>
        <button title="Delete" onClick={(e) => this.onConfirmDelete(e)}>
          <i className="las la-trash"></i>
        </button>
      </div>
      {confirmDeleteOpen && (
        <Lightbox
          mainSrc={
          <div className={'lightboxWrapper'}>
            <div className={'deleteConfirm'}>
              <h3>Delete Project: {this.props.project.name}</h3>
              <p>Are you sure you want to delete this project? {this.props.project.designs.length} designs within this project will be destroyed. <span className="red">This action cannot be undone.</span></p>
              <div className={'lightboxActions'}>
                <button onClick={() => this.setState({ confirmDeleteOpen: false})}>Cancel</button>
                <button onClick={this.onDelete}>Confirm</button>
              </div>
            </div>
          </div>
          }
          onCloseRequest={() => this.setState({ confirmDeleteOpen: false })}
          enableZoom={false}
          clickOutsideToClose={true}
        />
      )}
      {duplicateOpen && (
        <Lightbox
          mainSrc=<ProjectDuplicate onClose={this.closeProjectDuplicate}
          project={this.props.project}
          />
          onCloseRequest={this.closeProjectDuplicate}
          enableZoom={false}
          clickOutsideToClose={true}
        />
      )}
      {projectPdfOpen && (
          <Lightbox
            mainSrc=<ProjectRenderPDF
            project={this.props.project}
            imagesPopulated={this.state.imagesPopulated}
            processed={this.state.processed}
            total={this.props.project.designs.length}
            />
            onCloseRequest={() => this.setState({ projectPdfOpen: false })}
            enableZoom={false}
            clickOutsideToClose={true}
          />
      )}
      {projectPdfOpen && (
        <div className={styles['renderArea']}>
          <DesignList
            project={this.props.project}
            getCanvasImage={this.getCanvasImage}
            data={this.props.data}
          />
        </div>
      )}
      {notificationsOpen && (
        <Lightbox
          mainSrc=<NotificationsList
          notifications={notifications}
          />
          onCloseRequest={() => this.setState({ notificationsOpen: false })}
          enableZoom={false}
          clickOutsideToClose={true}
        />
      )}
      </div>
    )
  }
}

class ProjectList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {

  }

  onClickItem = project => {
    navigate('/project/'+project._id)
  }

  handleDelete = project => {
    this.props.handleDelete(project)
  }

  render(){
    let loadingIndicator = ''
    if(this.props.loadingProjects){
      loadingIndicator = <div style={{position: 'absolute',
      width: '100%', height: '100%', top: '0px', left: '0px',
      background: 'rgba(255,255,255,0.8)',
      zIndex: '10',
      color: '#ffffff'}}>
      <WaitLoader message="Loading Projects..." />
      </div>
    }
    let projectList = ''

    if(this.props.projects.length){
      this.props.projects.sort(function(a,b){
        let compare = 0
        if(a.created > b.created){
          compare = -1
        }else if(a.created < b.created){
          compare = 1
        }
        return compare
      })
      projectList = this.props.projects.map(function(project, index){
        let projectStatus = 'In Progress'
        return (
          <ProjectListItem
            key={project._id}
            onClick={this.onClickItem}
            project={project}
            handleDelete={this.handleDelete}
            handleCreateDesign={this.props.handleCreateDesign}
            projectStatus={projectStatus}
            data={this.props.data} />
        )
      }.bind(this))
    }
    return (
      <div className={'row ' + styles['projectList']}>
      {loadingIndicator}
      {projectList}
      </div>
    )
  }

}

export default ProjectList
