import React from 'react'
import styles from './project-list.module.css'
import {
  downloadDesignSavedFile
} from '../../../utils/design'
import axios from 'axios'
import { navigate } from '@reach/router'
import WaitLoader from '../../WaitLoader'
import Moment from 'moment'

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadingFile: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(){

  }

  onDownloadSavedFile = (designId, fileName) => {
    this.setState({
      downloadingFile: true
    }, function(){
      downloadDesignSavedFile(designId)
      .then(function(data){
        var path = data.designJPG.path
        var downloadFile = designId + '-design.jpg'
        if(fileName === 'specificationPackage'){
          path = data.specificationPackage.path
          downloadFile = designId + '-specification-package.pdf'
        }
        if(fileName === 'pdf'){
          path = data.pdf.path
          downloadFile = designId + '-design.pdf'
        }
        if(fileName === 'techDrawings'){
          path = data.techDrawings.path
          downloadFile = designId + '-technical-drawings.pdf'
        }
        axios({ url: path, method: 'GET', responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', downloadFile)
          document.body.appendChild(link)
          this.setState({
            downloadingFile: false
          }, function(){
            link.click()
            document.body.removeChild(link)
          })
        });
      }.bind(this))
      .catch((err)=>{
        this.setState({
          downloadingFile: false
        })
      })
    }.bind(this))
  }

  onGotoProject = (projectId) => {
    navigate('/project/'+projectId)
  }

  render(){
    let submittedTitle = null
    if(this.props.notifications.submitted.length > 0){
      submittedTitle = <h2>Submitted Designs</h2>
    }
    let submitted = this.props.notifications.submitted.map(function(item){
      let submittedDate = ''
      if(item.processingInfo){
        if(item.processingInfo.submittedDate){
          submittedDate = Moment(item.processingInfo.submittedDate).format('MM.DD.YYYY hh:mm:ss A')
        }
      }
      return <div key={item._id} className={styles['notificationsListItem']}>
        <div className={styles['listItem']}>
          <div className='row'>
            <div className='col-xs-8'>
              <div className='row'>
                <div className='col-xs-4 bold'>Name: </div>
                <div className='col-xs-8'>{item.name}</div>
              </div>
              <div className='row'>
                <div className='col-xs-4 bold'>Request Type: </div>
                <div className='col-xs-8'>{((item.processingInfo) ? item.processingInfo.requestType : '')}</div>
              </div>
              <div className='row'>
                <div className='col-xs-4 bold'>Submitted: </div>
                <div className='col-xs-8'>{submittedDate}</div>
              </div>
            </div>
            <div className='col-xs-4'>
              <div className='row'>
                <div className='col-xs-12'>
                  <button
                  onClick={(e)=>this.onGotoProject(item.project)}
                  className='button fadeHover'>
                  Go To Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }.bind(this))
    let completeTitle = null
    if(this.props.notifications.complete.length > 0){
      completeTitle = <h2>Processed Designs</h2>
    }
    let complete = this.props.notifications.complete.map(function(item){
      let submittedDate = ''
      if(item.processingInfo){
        if(item.processingInfo.submittedDate){
          submittedDate = Moment(item.processingInfo.submittedDate).format('MM.DD.YYYY hh:mm:ss A')
        }
      }
      let processedDate = ''
      if(item.processingInfo){
        if(item.processingInfo.processedDate){
          processedDate = Moment(item.processingInfo.processedDate).format('MM.DD.YYYY hh:mm:ss A')
        }
      }
      let downloadPDFButton = null
      let downloadJPGButton = null
      let downloadTechButton = null
      if(item.processingInfo){
        downloadPDFButton =
        <div className='col-xs-12'>
          <button
          onClick={(e) => this.onDownloadSavedFile(item._id,'pdf')}
          className='button fadeHover'>
          <i className="las la-download"></i>
          OVERVIEW PDF</button>
        </div>
        downloadJPGButton = <div className='col-xs-12'>
          <button
          onClick={(e) => this.onDownloadSavedFile(item._id,'designJPG')}
          className='button fadeHover'>
          <i className="las la-download"></i>
          DESIGN JPG</button>
        </div>
        if(item.processingInfo.requestType === 'Specification Package' || item.processingInfo.requestType === 'Specification Packet'){
          downloadPDFButton = <div className='col-xs-12'>
            <button
            onClick={(e) => this.onDownloadSavedFile(item._id,'specificationPackage')}
            className='button fadeHover'>
            <i className="las la-download"></i>
            OVERVIEW PDF
            </button>
          </div>
          downloadJPGButton = <div className='col-xs-12'>
            <button
            onClick={(e) => this.onDownloadSavedFile(item._id,'designJPG')}
            className='button fadeHover'>
            <i className="las la-download"></i>
            DESIGN JPG
            </button>
          </div>
          if(item.techDrawings){
            downloadTechButton = <div className='col-xs-12'>
              <button
              onClick={(e) => this.onDownloadSavedFile(item._id,'techDrawings')}
              className='button fadeHover'>
              <i className="las la-download"></i>
              TECHNICAL DRAWINGS PDF
              </button>
            </div>
          }
        }
      }
      return <div key={item._id} className={styles['notificationsListItem']}>
        <div className={styles['listItem']}>
          <div className='row'>
            <div className='col-xs-8'>
              <div className='row'>
                <div className='col-xs-4 bold'>Name: </div>
                <div className='col-xs-8'>{item.name}</div>
              </div>
              <div className='row'>
                <div className='col-xs-4 bold'>Request Type: </div>
                <div className='col-xs-8'>{((item.processingInfo) ? item.processingInfo.requestType : '')}</div>
              </div>
              <div className='row'>
                <div className='col-xs-4 bold'>Submitted On: </div>
                <div className='col-xs-8'>{submittedDate}</div>
              </div>
              <div className='row'>
                <div className='col-xs-4 bold'>Processed On: </div>
                <div className='col-xs-8'>{processedDate}</div>
              </div>
            </div>
            <div className='col-xs-4'>
              <div className='row'>
                <div className='col-xs-12'>

                </div>
                {downloadPDFButton}
                {downloadJPGButton}
                {downloadTechButton}
              </div>
            </div>
          </div>

        </div>
      </div>
    }.bind(this))
    let downloadIndicator = ''
    if(this.state.downloadingFile){
      downloadIndicator = <div className={styles['loaderWrapper']}>
      <WaitLoader message="Downloading File" />
      </div>
    }
    return (
      <div className={'lightboxWrapper'}>
        <div className={styles['notificationsList']}>
          {downloadIndicator}
          {submittedTitle}
          {submitted}
          {completeTitle}
          {complete}
        </div>
      </div>
    )
  }
}

export default Notifications
