import React from 'react'
import styles from './form.module.css'

class NewProjectForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      projectName: this.props.projectName,
      companyName: this.props.companyName
    }
  }

  handleUpdate = (e) => {
    if(e.target.name  === 'companyName'){
      this.setState({
        companyName: e.target.value
      })
    }else{
      this.setState({
        projectName: e.target.value
      })
    }
    this.props.handleUpdate(e)
  }

  render(){
    let {
      handleSubmit
    } = this.props

    let isDisabled = true
    if(this.state.projectName && this.state.companyName){
      isDisabled = false
    }

    return(
      <div className={styles[`new__project__form__wrapper`]}>
      <div className={styles[`new__project__form`]}>
      <form
        className={styles.form}
        method="post"
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <p className={styles[`form__instructions`]}>ENTER YOUR PROJECT DETAILS HERE</p>
        <div className={styles['field__inline']}>
          <label htmlFor={'projectName'}>
            Name:
          </label>
          <input
            id={'projectName'}
            name='projectName'
            component='input'
            value={this.state.projectName}
            onChange={this.handleUpdate}
            type='text'
            placeholder=''
          />
        </div>
        <div className={styles['field__inline']}>
          <label htmlFor={'company'}>
            Company:
          </label>
          <input
            id={'company'}
            name='companyName'
            component='input'
            value={this.state.companyName}
            onChange={this.handleUpdate}
            type='text'
            placeholder=''
          />
        </div>
        <p className={styles[`small__text`]}>Begin by naming your project, then click ‘Go’ to start building your elevator interior. Your progress will automatically be saved as you build your design. Within a project, you can create multiple elevator interiors.</p>
        <input
          className={styles[`form__button`]}
          type="submit"
          value="GO"
          disabled={isDisabled}
        />
      </form>
      </div>
      </div>
    )
  }
}
export default NewProjectForm
