import React from 'react'
import { ClipLoader } from 'react-spinners'
import { convertDrupalDesigns } from '../../../utils/design'
import { updateProfile, getUserInfo } from '../../../utils/user'

class ConvertingDesigns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      convertingData: false,
      conversionComplete: false,
      startIndex: 0,
      designCount: 10,
      progressMessage: '',
      user: null
    }
  }

  componentDidMount() {
    getUserInfo()
    .then(function(userData){
      this.setState({
        user: userData
      })
    }.bind(this))
  }

  convertDesigns = (start, count) => {
    convertDrupalDesigns(start, count)
    .then(function(data){
      if(data.totalDesigns && !data.designsComplete){
        this.setState({
          progressMessage: (start + count) + ' of ' + data.totalDesigns + ' designs imported.'
        }, function(){
          start = start+count
          return this.convertDesigns(start, count)
        }.bind(this))
      }
      if(data.designsComplete){
        this.setState({
          progressMessage: 'Designs import complete. Imported ' + data.totalDesigns + ' designs',
          convertingData: false,
          conversionComplete: true
        })
      }
    }.bind(this))
    .catch((err)=>{
      this.setState({
        progressMessage: err.message
      })
    })
  }

  proceed = () => {
    this.setState({
      convertingData: true,
      progressMessage: 'Initializing import.'
    }, function(){
      this.convertDesigns(this.state.startIndex, this.state.designCount)
    }.bind(this))
  }

  delayConversion = () => {
    let user = {...this.state.user}
    if(!user.eds){
      user.eds = {}
    }
    user.eds.drupalDataConverted = false
    user.eds.drupalDataConvertLater = true

    updateProfile(user)
    .then(function(data){
      this.props.handleConversionClosed(true)
    }.bind(this))
    .catch(function(err){
      console.log(err)
    })
  }

  render(){
    let progressIndicator = ''
    let proceedButton = <button disabled={this.state.convertingData}
      onClick={this.proceed}>
      Proceed</button>
    let completeButton = null
    if(this.state.convertingData){
      progressIndicator = <div style={{textAlign:'center'}}>
      <ClipLoader
        sizeUnit={"px"}
        size={25}
        color={'#e31837'}
        loading={true}
      />
      </div>
    }
    if(this.state.conversionComplete){
      proceedButton = null
      completeButton = <button disabled={this.state.convertingData} onClick={() => {this.props.handleConversionClosed(false)}}>
      Complete
      </button>
    }


    return(
      <div className={'lightboxWrapper'}>
      <div className={'deleteConfirm'}>
      <h2>Convert Legacy Data?</h2>
      <p>Click 'Proceed' to import your legacy EDS data into your projects
      collection or click 'Do this Later' to do it at another time. Once
      complete, you will be able to access all your existing EDS designs.</p>
      <p>{this.state.progressMessage}</p>
      {progressIndicator}
      <div className={'lightboxActions'}>
        <button onClick={this.delayConversion}>Do this Later</button>
        {proceedButton}
        {completeButton}
      </div>
      </div></div>
    )
  }
}

export default ConvertingDesigns
